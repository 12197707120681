import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { Image } from "react-bootstrap"
import { Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Integrantes = ({ data }) => (
  <Layout pageInfo={{ pageName: "Integrantes" }}>
    <SEO title="Integrantes" />
      <br />
      <br />
    <h1>Integrantes</h1>
    <Row>
      {data.lider.edges.map(({ node }) => (
        <Col sm={6} style={{textAlign: `-webkit-center`}}>
          <div class="image-area">
            <div class="img-wrapper">
              <Image src={"https://dev-openresearchlab.pantheonsite.io/" + node.relationships.field_fotografia.uri.url} alt={node.field_nombre} fluid />
              <h3>{node.field_nombre} {node.field_apellido}</h3>
              <Link to={node.path.alias}><p>{node.relationships.field_categoria.name}
                </p></Link>
              <ul>
                <li><Image src={"https://dev-openresearchlab.pantheonsite.io/" + node.relationships.field_pais.relationships.field_bandera.uri.url} style={{ width: `60px`, height: `60px` }} alt={node.field_nombre} /></li>
              </ul>
            </div>
          </div>
        </Col>
      ))}
    </Row>
    <br/>
    <Row>
      
      {data.allParticipantes.edges.map(({ node }) => (
        <Col sm={3} style={{textAlign: `-webkit-center`}}>
          <div class="image-area">
            <div class="img-wrapper">
              <Image src={"https://dev-openresearchlab.pantheonsite.io/" + node.relationships.field_fotografia.uri.url} alt={node.field_nombre} fluid />
              <h3>{node.field_nombre} {node.field_apellido}</h3>
              <Link to={node.path.alias}><p>{node.relationships.field_categoria.name}
               </p></Link>
              <ul>
                <li><Image src={"https://dev-openresearchlab.pantheonsite.io/" + node.relationships.field_pais.relationships.field_bandera.uri.url} style={{ width: `60px`, height: `60px` }} alt={node.field_nombre} /></li>
              </ul>
            </div>
          </div>
          
        </Col>
      ))}
    </Row>
  </Layout>
)
export default Integrantes

export const query = graphql`
  query allNodeIntegrantes {
    lider: allNodeIntegrantes(sort: {order: DESC, fields: field_nombre}, filter: {relationships: {field_categoria: {name: {eq: "Líder"}}}}) {
      edges {
        node {
          field_nombre
          field_apellido
          id
          path {
            alias
          }
          body {
            value
          }
          relationships {
            field_fotografia {
              uri {
                url
              }
            }
            field_categoria {
              name
            }
            field_campus {
              name
            }
            field_pais {
              relationships {
                field_bandera {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    
    allParticipantes: allNodeIntegrantes(sort: {order: ASC, fields: field_nombre}, filter: {relationships: {field_categoria: {name: {in: ["Especialista", "Profesor investigador", "Profesora investigadora","Estudiante Investigador","Estudiante Investigadora"]}}}}) {
      edges {
        node {
          field_nombre
          field_apellido
          id
          path {
            alias
          }
          body {
            value
          }
          relationships {
            field_fotografia {
              uri {
                url
              }
            }
            field_categoria {
              name
            }
            field_campus {
              name
            }
            field_pais {
              relationships {
                field_bandera {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
